.main-home-screen {
  flex: 1;
  text-align: center;
  width: "100%";
  align-items: center;
  padding-top: 2rem;
}

.br-inner-container .inner-container {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  text-align: center;
}

.club-search-text-input {
  font-family: "MontSerrat";
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 100%;
}

.club-search-text-input::placeholder {
  color: #b1b1b1;
}

.club-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.club-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(75, 75, 83);
  box-shadow: 0px 0px 10px 10px rgb(0 0 0 / 0%);
  transition: 0.3s;
  cursor: pointer;
  color: white;
}

.club-container:hover {
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.club-banner {
  object-fit: cover;
  height: 10vh;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.club-banner-container {
  background-color: rgb(114, 114, 114);
  height: 10vh;
  width: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap");

.club-container .club-name {
  font-size: 2rem;
  margin-bottom: 13px;
  margin-top: 10px;
  text-align: center;
  font-family: "Montserrat";
}

.add-element-icon {
  font-size: 30px;
}

.add-club-container {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}