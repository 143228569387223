.course-date-input-container {
  display: flex;
}

.course-date-input-container input:last-child {
  margin-right: 0px;
}

.course-edit-container {
  width: 100%;
}

.course-title-print-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.course-print-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}

.print-container {
  display: none;
  padding: 1rem;
}

.print-title-container {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.2rem;
}

.print-title {
  text-align: center;
  padding-bottom: 1rem;
}

@media print {
  .print-container {
    display: block;
  }
}

.email-copy-container {
  display: inline-block;
  border: 1px solid rgb(200, 200, 200);
  padding: 0.5rem;
  border-radius: 0px;
  color: rgb(56, 56, 56);
  cursor: pointer;
  transition: 0.2s;
  margin-bottom: 1rem;
}

.email-copy-container-copied {
  background-color: rgb(240, 240, 240);
}

.email-copy-check-container {
  color: rgb(153, 153, 153);
}

.email-copy-container-copied .email-copy-check-container {
  color: green;
}

.email-copy-check-container {
  margin-left: 10px;
}

@media screen and (max-width: 480px) {
  .course-participants-more-information-switch {
    display: none;
  }
}

.earnings-main-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.earnings-main-container .earnings-inner-container {
  margin-right: 2rem;
  flex: 1;
  padding: 1rem;
  box-shadow: 0 0 12px 5px rgb(0 0 0 / 10%);
  border-radius: 12px;
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: rgb(54, 54, 54);
}

.earnings-title {
  font-size: 1.3rem;
  border-bottom: 1px solid lightgray;
}

.earnings-main-container div:last-child {
  margin-right: 0;
}

.earnings-main-container .earnings-price {
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 600px) {
  .earnings-main-container {
    flex-direction: column;
  }

  .earnings-main-container .earnings-inner-container {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
