.modal-size {
  min-width: calc(100vw - var(--navbar-width));
  left: var(--navbar-width);
}

@media screen and (max-width: 1200px) {
  .modal-size {
    left: 0;
    width: 100vw;
  }
}

.modal-title {
  font-weight: bolder;
  font-size: 2.5rem;
}

@media screen and (max-width: 800px) {
  .modal-title {
    font-size: 1.8rem;
  }
}

.modal-title-container {
  margin-bottom: 1rem;
}

.modal-background {
  position: fixed;
  z-index: 1000;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
}

.modal-cross-container {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  filter: drop-shadow(10px, rgb(0, 0, 0));
}

.modal-cross-container .fa-times {
  color: black;
  font-size: 1.75rem;
}

.modal-cross-container:hover .fa-times {
  filter: drop-shadow(0px 0px 5px rgb(117, 117, 117));
  transition: 0.2s;
}

.modal-btn {
  font-size: 2rem;
  font-weight: 100;
  flex: 1;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: rgb(0, 165, 0);
  margin: 0;
  margin-top: 20px;
  cursor: pointer;
  font-family: "Montserrat";
  transition: background-color 500ms;
}

.modal-btn-red {
  background-color: red;
}

.modal-btn-loading {
  cursor: progress;
  background-color: rgb(139, 139, 139);
}

.modal-btn-disabled {
  cursor: default;
  background-color: rgb(139, 139, 139);
}
