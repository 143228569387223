.inner-container-club {
  width: 80%;
  margin: auto;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

@media screen and (max-width: 450px) {
  .inner-container-club {
    width: 90% !important;
  }
}

.inner-container-club > h1 {
  margin-bottom: 1rem;
}

.branches-container {
  float: left;
  width: 100%;
  text-align: left;
}

.branch-title {
  font-size: 2rem;
}

.branch-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--primary-color);
  text-align: center;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: filter 0.2s;
}

.branch-container:hover {
  filter: drop-shadow(0px 0px 5px rgb(117, 117, 117));
}

.club-name-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.club-settings-container {
  margin-left: 1rem;
  border: 2px solid rgb(69, 69, 69);
  color: rgb(69, 69, 69);
  border-radius: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: filter 0.2s;
}

.club-settings-container:hover {
  filter: drop-shadow(0px 0px 5px rgb(185, 185, 185));
}

.no-branches-available {
  display: flex;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 10rem;
  font-size: 1.2rem;
}

.br-padding-container {
  display: flex;
  flex-direction: column;
}
