.selected-member-container {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  font-size: 1.5rem;
}

.selected-member-container div {
  margin-right: 0.5rem;
}

.selected-member-container img {
  margin-right: 1rem;
}
