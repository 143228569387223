.subscreen-branch-inner-container {
  height: 100%;
}

.branches-title-container {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
}

.branch-subtitle-container {
  margin-bottom: 0.5rem;
}
