.user-table {
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.user-table td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
  cursor: pointer;
  padding-right: 0.5rem;
}

.user-table th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
}

.user-table td:first-child,
.user-table th:first-child {
  padding-left: 1rem;
}

.user-table td:last-child,
.user-table th:last-child {
  padding-right: 1rem;
}

.user-table-roles-inner-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* .user-table tbody td {
  padding-left: 1.4rem;
  padding-top: 10px;
  padding-bottom: 10px;
} */

@media screen and (max-width: 840px) {
  /* .user-table tbody td {
    padding-left: 0.4rem;
  } */
}

/* .user-table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
} */

@media screen and (max-width: 840px) {
  /* .user-table th {
    font-size: 0.9rem;
  } */
}

/* .user-table tbody tr {
  background-color: #313131;
  cursor: pointer;
}

.user-table tbody tr:nth-child(2n + 1) {
  background-color: #474747;
}

.user-table tbody tr:hover {
  filter: brightness(1.2);
}

.user-table thead tr {
  background-color: #2a2a2a;
} */

.action-btn-container {
  position: relative;
  padding: 0px;
  /* padding-top: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn {
  outline: none;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 20px;
  cursor: pointer;
}

.loading-table-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media screen and (max-width: 960px) {
  .user-table-roles {
    display: none;
  }
  .user-table-status {
    display: none;
  }
  .user-table-rights {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .user-table-email {
    display: none;
  }
}

.user-table-membershipNumber-title:before {
  content: "Mitgliedsnummer";
}

@media screen and (max-width: 1000px) {
  .user-table-membershipNumber-title:before {
    content: "#";
  }
}
