.booking-date-container {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #3f3f3f;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.booking-date-inner-container {
  display: flex;
  flex-direction: row;
}

.booking-date-text-container {
  margin-right: 1rem;
}

.booking-date-icon-container {
  margin-right: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.table-time-container {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  height: 100%;
  background-color: white;
  width: 5rem;
}

.table-time-container:nth-child(2) {
  border-left: 1px solid rgb(168, 168, 168);
}
.table-time-container-last {
  border-right: 1px solid rgb(168, 168, 168);
}

.table-title-container {
  display: flex;
  justify-content: space-between;
  background-color: #242424;
  height: 3rem;
  align-items: center;
  color: white;
  font-size: 1.4rem;
  border: none;
}

.table-title-time-container {
  justify-content: center;
}

.table-title-container .arrow-container {
  margin-right: 1rem;
  margin-left: 1rem;
  width: 1rem;
  cursor: pointer;
}

.table-time-inner-container {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(168, 168, 168);
  /* border-left: 1px solid rgb(168, 168, 168); */
}

.booking-table {
  position: relative;
  z-index: 0;
}

.table-column-container {
  position: relative;
  display: flex;
  height: 900px;
  background-image: repeating-linear-gradient(
    45deg,
    #efefef,
    #efefef 22px,
    white 22px,
    white 44px
  );
  z-index: 0;
}

.table-object-main-container:nth-child(3) {
  border-left: 1px solid rgb(168, 168, 168);
}

.table-absolute-container {
  position: absolute;
  width: 100%;
  height: 50%;
  z-index: 1;
  background-color: rgba(100, 100, 100, 0.5);
}

.booking-table-head {
  position: sticky;
  top: var(--header-height);
  /* top: 0; */
  z-index: 1;
}

.table-title-main-container {
  display: flex;
}

.table-object-container {
  flex: 1;
  height: 100%;
  border-right: 1px solid rgb(168, 168, 168);
  /* border-bottom: 1px solid rgb(168, 168, 168); */
  display: flex;
  flex-flow: column;
}

.table-object-container:nth-child(2) {
  border-left: 1px solid rgb(168, 168, 168);
}

.table-column-container
  .table-time-container:last-of-type
  .table-time-inner-container {
  border-left: none;
}

@media screen and (max-width: 840px) {
  .table-secondTimeContainer {
    display: none;
  }
}
