* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
#root {
  font-family: "PT Sans", sans-serif;
  /* font-family: "Montserrat"; */
  height: 100%;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #686868;
}

:root {
  --header-height: 60px;
  --navbar-width: 18rem;
  --border-color: rgb(236, 236, 236);
  --primary-color: #c31924; /* #2c2a31 */
  --primary-text-color: white;
  --secondary-color: rgb(138, 16, 24);
  --user-element-color: var(--primary-color);
  --tertiary-color: white; /*rgb(22, 22, 22)*/
  --tertiary-text-color: #272727; /*rgb(22, 22, 22)*/
  --loading-color: var(--primary-color);
}

.main-inner-container {
  /* height: calc(100% - var(--header-height)); */
  height: 100%;
  /* overflow-x: hidden; */
}

.loading-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 100%;
  /* min-height: 80vh; */
  align-items: center;
  justify-content: center;
}

.white {
  color: white;
}

.black {
  color: black;
}

.center {
  text-align: center;
}

.right {
  float: right;
}

.no-btn {
  outline: none;
  border: none;
  cursor: pointer;
}

.no-underline {
  text-decoration: none;
}

.main-home-screen-clubs {
  display: flex;
  flex: 1 1;
}

.subscreen-main-container {
  background-color: rgb(228, 228, 228);
  width: 100%;
  padding: 1rem;
  flex: 1;
}

@media screen and (max-width: 600px) {
  .subscreen-main-container {
    padding: 0;
  }
}

.subscreen-box-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
}

.center-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main-info-screen {
  padding: 4rem;
}

.medium-space {
  margin-bottom: 1.5rem;
}

.bold {
  font-weight: bold;
}

.margin-right {
  margin-right: 1rem;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.no-margin {
  margin: 0 !important;
}

.inline-block {
  display: inline-block;
}

.create-btn {
  display: flex;
  flex-direction: row;
  font-family: "Montserrat";
  font-size: 1.2rem;
  color: rgb(103, 124, 217);
  cursor: pointer;
  text-decoration: none;
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.create-btn .create-icon-container {
  margin-right: 0.5rem;
}

.cookie-banner {
  width: 100vw;
  position: fixed;
  background-color: white;
  z-index: 10;
  bottom: 0;
  padding: 2rem;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
}

.cookie-banner h2 {
  margin-bottom: 0.5rem;
}

.cookie-btn-container {
  margin-top: 1.2rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cookie-btn-container div {
  padding: 0.5rem;
  font-weight: bold;
  cursor: pointer;
}

.cookie-deny-btn {
  border: none;
  color: rgb(80, 80, 80);
}

.cookie-accept-btn {
  margin-left: 2rem;
  border: 2px solid black;
}

.cookie-accept-btn:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.sector-select-main-container {
  display: flex;
}

.sector-select-input-container {
  display: flex;
  flex: 1;
}

.select-sector-btn {
  display: flex;
}

@media screen and (max-width: 870px) {
  .sector-select-main-container {
    flex-direction: column;
  }

  .select-sector-btn {
    display: flex;
    margin-top: 1rem;
    flex: 1;
    justify-content: space-between;
  }

  .multiple-dates-edit-btn {
    flex: 1;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}

.margin-left {
  margin-left: 0.3rem;
}

.main-info-container {
  position: fixed;
  z-index: 1000;
  bottom: 1rem;
  right: 0;
}

.main-info-progress-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 4px;
  background-color: #686868;
  animation: progress linear;
  animation-duration: 40s;
}

.main-info-element:hover .main-info-progress-container {
  animation-play-state: paused;
}

@keyframes progress {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.main-info-element {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
  max-width: 50vw;
  min-width: 25vw;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 600px) {
  .main-info-element {
    max-width: calc(100vw - 4rem);
  }
}

.main-info-inner-element {
  display: flex;
  width: 100%;
  padding: 1.25rem;
}

.main-info-text-container {
  flex: 1;
}

.main-info-icon-container {
  display: flex;
}

.main-info-template-icon {
  height: 1.2rem;
  width: 1.2rem !important;
  margin-right: 1rem;
}

.main-info-error-icon {
  color: #ff2e2e;
}

.main-info-info-icon {
  color: #fdbf2f;
}

.main-info-success-icon {
  color: #1ce025;
}

.main-info-close-icon {
  margin-left: 1.2rem;
  color: #5a5a5a;
  font-size: 1.1rem;
  cursor: pointer;
}
