.table-course-users {
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.table-course-user-printmode {
  border-collapse: separate;
  font-size: 0.9rem;
}

.table-course-user-printmode td,
.table-course-user-printmode th {
  padding-right: 1rem;
}

.table-course-users td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
  cursor: pointer;
  padding-right: 0.5rem;
}

.table-course-users td:first-child,
.table-course-users th:first-child {
  padding-left: 1rem;
}

.table-course-users td:last-child,
.table-course-users th:last-child {
  padding-right: 1rem;
}

.table-course-users th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
}

@media screen and (max-width: 750px) {
  .table-course-users-address {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .table-course-users-birthdate {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .table-course-users-phonenumber {
    display: none;
  }
  .table-course-users {
    font-size: 0.8rem;
  }
  .table-course-users th {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 350px) {
  .table-course-users-email-print {
    display: none;
  }
}
