.course-type-container {
  position: relative;
  margin-bottom: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.course-type-inner-container {
  position: relative;
  border: 1px solid rgb(195, 195, 195);
  height: 2.75rem;
  border-radius: 50px;
  display: flex;
  text-align: center;
}
@media screen and (max-width: 450px) {
  .course-type-container {
    font-size: 0.8rem;
  }
}

.course-type-inner-container div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  border-radius: 50px;
  color: rgb(80, 80, 80);
  transition: color 0.4s ease-in;
  -webkit-transition: color 0.4s ease-in;
}

.course-type-selected {
  color: var(--primary-text-color) !important;
}

.course-type-background {
  position: absolute;
  background-color: var(--primary-color);
  width: 33.3%;
  height: 100%;
  border-radius: 50px;
  -webkit-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}

.course-type-background:hover {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.course-type-background-1 {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.course-type-background-2 {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
.course-type-background-3 {
  -webkit-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
}
