.booking-main-container {
  background-color: white;
  cursor: pointer;
  border-bottom: 1px solid rgb(168, 168, 168);
}

.booking-main-inner-container {
  padding-left: 0.5rem;
  padding-top: 2px;
  font-size: 0.6rem;
  font-family: "Montserrat";
  color: rgb(24, 24, 24);
}

.booking-bookable {
}

.booking-bookable:hover {
  border: 2px solid black;
}

.booking-booked {
  border: none;
  border-left: 0.25rem solid rgb(0, 245, 151);
  border-bottom: 1px solid rgb(168, 168, 168);
  background-color: rgb(206, 255, 247);
}

.booking-disabled {
  background-color: rgb(207, 207, 207);
  cursor: default;
}

.booking-main-container:nth-child(2n) {
}
