.accept-container {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.accept-container-checkbox {
  margin-right: 0.5rem;
  margin-top: 3px;
}
