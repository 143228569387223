.calendar-table {
  width: 100%;
  text-align: center;
  table-layout: fixed;
  margin-bottom: 2rem;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.calendar-table td,
.calendar-table th {
  height: 2.5rem;
}

.calendar-table td {
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
}

.calendar-table td:hover {
  /* box-sizing: border-box; */
  /* border: 1px solid black; */
}

.day-no-focus {
  color: rgb(165, 165, 165);
}

.calendar-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #3b3b3b;
}

.calendar-date {
  font-size: 1.2rem;
  font-weight: bold;
}

.arrow-container {
  display: flex;
  flex-direction: row;
}

.arrow-container div {
  margin-right: 1rem;
  cursor: pointer;
}

.day-circle {
  display: flex;
  border-radius: 50%;
  background-color: rgb(141, 209, 255);
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.day-outer-circle {
  display: flex;
  border-radius: 50%;
  border: 2px solid rgb(141, 209, 255);
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.day-circle-low {
  background-color: rgb(216, 240, 255);
}

.calendar-table td:hover .day-circle-hover {
  display: flex;
  border-radius: 50%;
  background-color: rgb(210, 237, 255);
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.day-today {
  color: rgb(0, 14, 95);
  font-weight: bold;
}
