.profile-location-container {
  display: flex;
}

.profile-location-container input:last-child {
  margin-right: 0px;
}

.zipcode-input {
  flex: 1;
}

.street-input {
  flex: 4;
}

.city-input {
  flex: 4;
}

.house-number-input {
  flex: 1;
}
