.btn {
  font-size: 2rem;
  font-weight: 100;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background-color: rgb(0, 165, 0);
  margin: 0;
  margin-bottom: 20px;
  cursor: pointer;
  font-family: "Montserrat";
  transition: background-color 500ms;
}

.btn-red {
  background-color: red;
}

.btn-loading {
  background-color: rgb(139, 139, 139) !important;
}

.btn-disabled {
  cursor: default;
  background-color: rgb(139, 139, 139);
}

.btn-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
