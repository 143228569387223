.single-course-main-container {
  display: flex;
}

.single-course-img {
  color: rgb(194, 194, 194);
  min-height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
  margin-right: 1rem;
}

@media screen and (max-width: 720px) {
  .single-course-img {
    display: block !important;
    width: 100%;
    min-height: 0 !important;
    max-height: 10rem !important;
    margin-bottom: 0.6rem;
  }

  .single-course-main-container {
    flex-direction: column;
  }
}
