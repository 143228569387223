.main-login-screen {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(to top right, rgb(44, 194, 231), rgb(205, 34, 235)); */
  background-color: rgb(241, 241, 241);

  font-family: "Montserrat";
  flex-direction: column;
}

.custom-shape-divider-bottom-1633269866 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1633269866 svg {
  position: relative;
  display: block;
  width: calc(137% + 1.3px);
  height: 331px;
}

.custom-shape-divider-bottom-1633269866 .shape-fill {
  /* fill: #6492c9; */
  fill: #c31924;
}

.signUpField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid lightgray;
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 10px;
  background-color: white;
  max-width: 800px;
  z-index: 1;
}

.input-field {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border-bottom: 2px dashed rgb(153, 153, 153);
  margin: 1rem auto 2rem;
  transition: 500ms;
  z-index: 1;
}

.input-field::after {
  content: "";
  position: relative;
  display: block;
  height: 4px;
  width: 100%;
  background-color: #c31924;
  transform: scaleX(0);
  transform-origin: 0%;
  opacity: 0;
  transition: all 500ms ease;
  top: 2px;
}

.input-field:focus-within {
  border-color: transparent;
}

.input-field:focus-within::after {
  transform: scaleX(1);
  opacity: 1;
}

.text-input {
  outline: none;
  border: none;
  overflow: hidden;
  margin: 0;
  width: 100%;
  padding: 0.25rem 0;
  background: none;
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  transition: border 500ms;
}

/* .text-input:valid {
  color: yellowgreen;
}

.text-input:invalid {
  color: orangered;
} */

.input-label {
  left: 0px;
  position: absolute;
  transform: translateY(0.25rem);
  transform-origin: 0%;
  transition: transform 400ms;
  font-size: 1.2rem;
  z-index: -1;
}

.input-field:focus-within .input-label,
.text-input:not(:placeholder-shown) + .input-label {
  transform: scale(0.9) translateY(-2rem);
}

.text-input::placeholder {
  color: #b1b1b1;
}

.text-input-full-width {
  width: 98%;
}

@media screen and (max-width: 840px) {
  .text-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }
}

.textContainer {
  text-align: center;
  padding-bottom: 2rem;
}

.password-forgotten {
  text-align: center;
  margin-bottom: 0.75rem;
  text-decoration: none;
  color: rgb(29, 29, 29);
}

.small-text {
  font-size: 16px;
}

.google-button {
  display: flex;
  width: 30%;
  min-width: 300px;
  align-items: center;
  border-width: 0px;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  white-space: nowrap;
  border-radius: 3px;
  padding: 0px 8px;
  vertical-align: auto;
  font-weight: bold;
  outline: none;
  color: rgb(66, 82, 110);
  height: 40px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px;
  margin: auto;
  cursor: pointer;
  transition: 200ms;
}

.google-button:hover {
  background-color: rgba(240, 240, 240, 0.9);
}

.google-img {
  height: 18px;
  margin-right: 8px;
  width: 18px;
}

.login-btn {
  color: white;
  /* background: linear-gradient(to right, #6492c9, rgb(124, 85, 233)); */
  background: linear-gradient(to right, #c31924, rgb(235, 7, 83));
  /* background-color: #6492c9; */
  outline: none;
  border: none;
  border-radius: 34px;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.input-row-container {
  display: flex;
}

@media screen and (max-width: 480px) {
  .input-row-container {
    flex-direction: column;
  }
}

.input-row-container .input-field {
  margin-right: 1rem;
}

.input-row-container .input-field:last-child {
  margin-right: 0px;
}

.sign-up-accept-container {
  margin-bottom: 1rem;
  font-size: 0.8rem;
}

.login-logo {
  width: 200px;
  max-width: 100%;
  margin-bottom: 1rem;
}
