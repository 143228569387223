.switch-setting {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: .75rem;
  font-size: 1.2rem;
}

.switch-container {
  margin-right: 1rem;
}
