.subtitle-two-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.subtitle-two-container span {
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-size: 1.4rem;
  margin-right: 0.2rem;
  text-decoration: underline;
}

.info-icon-container {
  font-size: 0.9rem;
  cursor: pointer;
  color: rgb(56, 56, 56);
}

.info-text-container {
  position: absolute;
  display: none;
  align-items: center;
  transform: translate(16px, -56%);
  max-width: 50vw;
}

.info-text-inner-container {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  padding: 1.25rem;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
}

.info-text-triangle {
  width: 0px;
  height: 0px;

  border-top: 10px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--primary-color);
  z-index: 1;
}

.info-icon-container:hover .info-text-container {
  display: flex;
}
