@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap");

.branch-small-label {
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.book-modal-input {
  /* flex: 1; */
  width: 100%;
  margin-top: 1rem;
  padding: 10px;
  outline: none;
  border: transparent;
  font-family: "Montserrat";
  font-size: 1.5rem;
}

.book-modal-input:hover {
  box-shadow: 0 0 10px 10px rgb(0 0 0 / 10%);
}

.book-modal-table-list {
  flex: 1;
  width: 100%;
  font-size: 1.25rem;
}

.book-modal-table-list tbody tr td:last-child {
  font-weight: bold;
}
