.info-container {
  display: flex;
  background-color: rgb(238, 238, 238);
  padding: 0.6rem;
  border-radius: 10px;
  /* color: white; */
  margin-bottom: 1rem;
}

.info-circle-icon-container {
  background-color: white;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.info-icon {
  color: #ffd166;
  transform: scale(1.1);
}

.info-inner-container {
  padding-top: 2px;
}
