/* .br-navbar {
  position: fixed;
  background-color: #2c2a31;
} */

.navbar-objects {
  display: none;
}

.navbar-objects-selected {
  display: block;
}