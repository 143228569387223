@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap");

.branch-modal-inner {
  z-index: 1001;
  min-width: 20%;
  max-width: 90vmin;
  padding: 50px;
  background-color: white;
  color: black;
  border-radius: 25px;
  position: relative;
}

.branch-title {
  font-weight: 100;
  font-family: "Montserrat";
}

.branch-text {
  font-weight: 100;
  font-family: "Montserrat";
  font-size: 0.8rem;
}

