@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
#root {
  font-family: "PT Sans", sans-serif;
  /* font-family: "Montserrat"; */
  height: 100%;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #686868;
}

:root {
  --header-height: 60px;
  --navbar-width: 18rem;
  --border-color: rgb(236, 236, 236);
  --primary-color: #c31924; /* #2c2a31 */
  --primary-text-color: white;
  --secondary-color: rgb(138, 16, 24);
  --user-element-color: var(--primary-color);
  --tertiary-color: white; /*rgb(22, 22, 22)*/
  --tertiary-text-color: #272727; /*rgb(22, 22, 22)*/
  --loading-color: var(--primary-color);
}

.main-inner-container {
  /* height: calc(100% - var(--header-height)); */
  height: 100%;
  /* overflow-x: hidden; */
}

.loading-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 100%;
  /* min-height: 80vh; */
  align-items: center;
  justify-content: center;
}

.white {
  color: white;
}

.black {
  color: black;
}

.center {
  text-align: center;
}

.right {
  float: right;
}

.no-btn {
  outline: none;
  border: none;
  cursor: pointer;
}

.no-underline {
  text-decoration: none;
}

.main-home-screen-clubs {
  display: flex;
  flex: 1 1;
}

.subscreen-main-container {
  background-color: rgb(228, 228, 228);
  width: 100%;
  padding: 1rem;
  flex: 1 1;
}

@media screen and (max-width: 600px) {
  .subscreen-main-container {
    padding: 0;
  }
}

.subscreen-box-container {
  display: flex;
  flex: 1 1;
  height: 100%;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
}

.center-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main-info-screen {
  padding: 4rem;
}

.medium-space {
  margin-bottom: 1.5rem;
}

.bold {
  font-weight: bold;
}

.margin-right {
  margin-right: 1rem;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.no-margin {
  margin: 0 !important;
}

.inline-block {
  display: inline-block;
}

.create-btn {
  display: flex;
  flex-direction: row;
  font-family: "Montserrat";
  font-size: 1.2rem;
  color: rgb(103, 124, 217);
  cursor: pointer;
  text-decoration: none;
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.create-btn .create-icon-container {
  margin-right: 0.5rem;
}

.cookie-banner {
  width: 100vw;
  position: fixed;
  background-color: white;
  z-index: 10;
  bottom: 0;
  padding: 2rem;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
}

.cookie-banner h2 {
  margin-bottom: 0.5rem;
}

.cookie-btn-container {
  margin-top: 1.2rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cookie-btn-container div {
  padding: 0.5rem;
  font-weight: bold;
  cursor: pointer;
}

.cookie-deny-btn {
  border: none;
  color: rgb(80, 80, 80);
}

.cookie-accept-btn {
  margin-left: 2rem;
  border: 2px solid black;
}

.cookie-accept-btn:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.sector-select-main-container {
  display: flex;
}

.sector-select-input-container {
  display: flex;
  flex: 1 1;
}

.select-sector-btn {
  display: flex;
}

@media screen and (max-width: 870px) {
  .sector-select-main-container {
    flex-direction: column;
  }

  .select-sector-btn {
    display: flex;
    margin-top: 1rem;
    flex: 1 1;
    justify-content: space-between;
  }

  .multiple-dates-edit-btn {
    flex: 1 1;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}

.margin-left {
  margin-left: 0.3rem;
}

.main-info-container {
  position: fixed;
  z-index: 1000;
  bottom: 1rem;
  right: 0;
}

.main-info-progress-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 4px;
  background-color: #686868;
  -webkit-animation: progress linear;
          animation: progress linear;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
}

.main-info-element:hover .main-info-progress-container {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@-webkit-keyframes progress {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

@keyframes progress {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.main-info-element {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
  max-width: 50vw;
  min-width: 25vw;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 600px) {
  .main-info-element {
    max-width: calc(100vw - 4rem);
  }
}

.main-info-inner-element {
  display: flex;
  width: 100%;
  padding: 1.25rem;
}

.main-info-text-container {
  flex: 1 1;
}

.main-info-icon-container {
  display: flex;
}

.main-info-template-icon {
  height: 1.2rem;
  width: 1.2rem !important;
  margin-right: 1rem;
}

.main-info-error-icon {
  color: #ff2e2e;
}

.main-info-info-icon {
  color: #fdbf2f;
}

.main-info-success-icon {
  color: #1ce025;
}

.main-info-close-icon {
  margin-left: 1.2rem;
  color: #5a5a5a;
  font-size: 1.1rem;
  cursor: pointer;
}

.main-login-screen {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(to top right, rgb(44, 194, 231), rgb(205, 34, 235)); */
  background-color: rgb(241, 241, 241);

  font-family: "Montserrat";
  flex-direction: column;
}

.custom-shape-divider-bottom-1633269866 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.custom-shape-divider-bottom-1633269866 svg {
  position: relative;
  display: block;
  width: calc(137% + 1.3px);
  height: 331px;
}

.custom-shape-divider-bottom-1633269866 .shape-fill {
  /* fill: #6492c9; */
  fill: #c31924;
}

.signUpField {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid lightgray;
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 10px;
  background-color: white;
  max-width: 800px;
  z-index: 1;
}

.input-field {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border-bottom: 2px dashed rgb(153, 153, 153);
  margin: 1rem auto 2rem;
  transition: 500ms;
  z-index: 1;
}

.input-field::after {
  content: "";
  position: relative;
  display: block;
  height: 4px;
  width: 100%;
  background-color: #c31924;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: 0%;
          transform-origin: 0%;
  opacity: 0;
  transition: all 500ms ease;
  top: 2px;
}

.input-field:focus-within {
  border-color: transparent;
}

.input-field:focus-within::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  opacity: 1;
}

.text-input {
  outline: none;
  border: none;
  overflow: hidden;
  margin: 0;
  width: 100%;
  padding: 0.25rem 0;
  background: none;
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  transition: border 500ms;
}

/* .text-input:valid {
  color: yellowgreen;
}

.text-input:invalid {
  color: orangered;
} */

.input-label {
  left: 0px;
  position: absolute;
  -webkit-transform: translateY(0.25rem);
          transform: translateY(0.25rem);
  -webkit-transform-origin: 0%;
          transform-origin: 0%;
  transition: -webkit-transform 400ms;
  transition: transform 400ms;
  transition: transform 400ms, -webkit-transform 400ms;
  font-size: 1.2rem;
  z-index: -1;
}

.text-input:not(:-ms-input-placeholder) + .input-label {
  transform: scale(0.9) translateY(-2rem);
}

.input-field:focus-within .input-label,
.text-input:not(:placeholder-shown) + .input-label {
  -webkit-transform: scale(0.9) translateY(-2rem);
          transform: scale(0.9) translateY(-2rem);
}

.text-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.text-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.text-input::placeholder {
  color: #b1b1b1;
}

.text-input-full-width {
  width: 98%;
}

@media screen and (max-width: 840px) {
  .text-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }
}

.textContainer {
  text-align: center;
  padding-bottom: 2rem;
}

.password-forgotten {
  text-align: center;
  margin-bottom: 0.75rem;
  text-decoration: none;
  color: rgb(29, 29, 29);
}

.small-text {
  font-size: 16px;
}

.google-button {
  display: flex;
  width: 30%;
  min-width: 300px;
  align-items: center;
  border-width: 0px;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  white-space: nowrap;
  border-radius: 3px;
  padding: 0px 8px;
  vertical-align: auto;
  font-weight: bold;
  outline: none;
  color: rgb(66, 82, 110);
  height: 40px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px;
  margin: auto;
  cursor: pointer;
  transition: 200ms;
}

.google-button:hover {
  background-color: rgba(240, 240, 240, 0.9);
}

.google-img {
  height: 18px;
  margin-right: 8px;
  width: 18px;
}

.login-btn {
  color: white;
  /* background: linear-gradient(to right, #6492c9, rgb(124, 85, 233)); */
  background: linear-gradient(to right, #c31924, rgb(235, 7, 83));
  /* background-color: #6492c9; */
  outline: none;
  border: none;
  border-radius: 34px;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.input-row-container {
  display: flex;
}

@media screen and (max-width: 480px) {
  .input-row-container {
    flex-direction: column;
  }
}

.input-row-container .input-field {
  margin-right: 1rem;
}

.input-row-container .input-field:last-child {
  margin-right: 0px;
}

.sign-up-accept-container {
  margin-bottom: 1rem;
  font-size: 0.8rem;
}

.login-logo {
  width: 200px;
  max-width: 100%;
  margin-bottom: 1rem;
}

.small-space {
  margin-top: 8px;
  margin-bottom: 8px;
}

.small-Text-Red {
  color: #d63232;
  font-size: 16px;
  text-align: center;
}

.main-home-screen {
  flex: 1 1;
  text-align: center;
  width: "100%";
  align-items: center;
  padding-top: 2rem;
}

.br-inner-container .inner-container {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  text-align: center;
}

.club-search-text-input {
  font-family: "MontSerrat";
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 100%;
}

.club-search-text-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.club-search-text-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.club-search-text-input::placeholder {
  color: #b1b1b1;
}

.club-grid {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.club-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(75, 75, 83);
  box-shadow: 0px 0px 10px 10px rgb(0 0 0 / 0%);
  transition: 0.3s;
  cursor: pointer;
  color: white;
}

.club-container:hover {
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.club-banner {
  object-fit: cover;
  height: 10vh;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.club-banner-container {
  background-color: rgb(114, 114, 114);
  height: 10vh;
  width: 100%;
}

.club-container .club-name {
  font-size: 2rem;
  margin-bottom: 13px;
  margin-top: 10px;
  text-align: center;
  font-family: "Montserrat";
}

.add-element-icon {
  font-size: 30px;
}

.add-club-container {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vert-navbar {
  /* height: calc(100vh - var(--header-height)); */
  width: var(--navbar-width);
  height: 100vh;
  position: fixed;
  left: 0;
  transition: -webkit-transform 300ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 300ms cubic-bezier(0, 0, 0.2, 1);
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.vert-navbar-scrollable {
  /* height: 100%; */
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
}

@media screen and (max-width: 1200px) {
  .vert-navbar {
    -webkit-transform: translate3d(calc(var(--navbar-width) * -1), 0, 0);
            transform: translate3d(calc(var(--navbar-width) * -1), 0, 0);
  }
}

.vert-navbar-scrollable::-webkit-scrollbar {
  width: 0.25rem;
}

.vert-navbar-scrollable::-webkit-scrollbar-track {
  background: #1e1e24;
}

.vert-navbar-scrollable::-webkit-scrollbar-thumb {
  background: #584e74;
}

.vert-navbar-mobile {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.nav-background {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100%;
  z-index: -10;
  transition: background-color 300ms ease-out;
}

.show-nav-background {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.cs-navbar {
  height: 100vh;
  position: fixed;
  background-color: #2c2a31;
  padding-top: 1rem;
}

.navbar-element {
  display: flex;
  flex-direction: row;
  color: var(--primary-text-color);
  font-size: 1.2rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
  /* border-bottom: 1px solid rgb(26, 25, 29); */
  cursor: pointer;
  text-decoration: none;
}

.navbar-element-selected {
  color: white;
  background-color: var(--secondary-color);
}

.navbar-element:last-child {
  border-bottom: none;
}

.navbar-element > .navbar-icon-container {
  margin-right: 1rem;
  min-width: 2rem;
}

.navbar-element-logo {
  font-weight: bold;
  font-family: "Montserrat";
}

.navbar-element > .navbar-icon-logo-container {
  margin-right: 0.75rem;
}

.navbar-create-element {
  justify-content: center;
  padding-right: 1rem;
}

.navbar-back-element {
  background-color: rgb(34, 34, 34);
  border-top: 1px solid rgb(50, 50, 50);
  justify-content: center;
  padding-right: 1rem;
  height: none;
}

.navbar-element-logo {
  flex: 0 0 auto;
  background-color: var(--tertiary-color);
  color: var(--tertiary-text-color);
  height: var(--header-height);
  /* border-bottom: 1px solid rgb(50, 50, 50); */
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.navbar-element-user {
  background-color: var(--user-element-color);
  /* border-bottom: 1px solid rgb(50, 50, 50); */
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-profile-pic {
  width: 4rem;
}

.navbar-element-user-textcontainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-element-user-logout {
  width: 80%;
  border: 1px solid white;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  padding: 0.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  text-align: center;
  background-color: transparent;
  transition: all 300ms ease;
}

.navbar-element-user-logout:hover {
  color: black;
  background-color: white;
}

.navbar-element-indented {
  padding-left: 4rem;
}

.name-spaceholder-container {
  display: flex;
}

.name-spaceholder {
  height: 1em;
  width: 4rem;
  background-color: var(--secondary-color);
  border-radius: 20px;
  margin-right: 0.5rem;
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
          animation: skeleton-loading 1s linear infinite alternate;
}

.name-spaceholder-fName {
  width: 4rem;
}

.name-spaceholder-lName {
  width: 6rem;
}

@-webkit-keyframes skeleton-loading {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.7;
  }
}

.menubar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
  padding-left: 1rem;
  padding-right: 1rem;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.menubar-path-main-container {
  display: flex;
  align-items: center;
}

.menubar-path-container {
  font-size: 0.85rem;
  font-weight: bold;
  color: rgb(78, 78, 78);
  /* display: none; */
  margin-left: var(--navbar-width);
  font-family: "Montserrat";
}

@media (max-width: 1200px) {
  .menubar-path-container {
    margin-left: 1.5rem;
  }
}

.menubar-container-icon {
  display: block;
}

.menubar-path-text {
  text-decoration: none;
  color: rgb(78, 78, 78);
}

@media (max-width: 768px) {
  .menubar-path-container {
    font-size: 0.75rem;
  }
}

.menu-bell-icon {
  cursor: pointer;
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  60% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

@keyframes wiggle {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  60% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

.menu-bell-icon:hover {
  -webkit-animation: wiggle 0.5s 1;
          animation: wiggle 0.5s 1;
}

.menubar-bell-icon {
  position: relative;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 6px rgba(255, 102, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0 0 0 6px rgba(255, 102, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 102, 0, 0);
  }
}

.menubar-bell-new-notification {
  position: absolute;
  background-color: rgb(255, 102, 0);
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  right: 0;
  z-index: 1;

  box-shadow: 0 0 0 0 rgba(255, 102, 1);
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation: pulse 2s 10;
          animation: pulse 2s 10;
}

.notifications-container {
  position: absolute;
  height: 0;
  width: 20rem;
  max-width: 70vw;
  right: 0;
  -webkit-transform: translate(0, 20px);
          transform: translate(0, 20px);
  overflow: auto;
  transition: height 0.3s ease;
  overflow: hidden;
}

.notifications-inner-container {
  background-color: white;
  margin: 0 0px 20px 20px;
  /* box-shadow: none; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 90%;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.2);
}

.notifications-inner-container::-webkit-scrollbar {
  width: 0.25rem;
}

.notifications-inner-container::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.notifications-inner-container::-webkit-scrollbar-thumb {
  background: #bebebe;
}

.show-notification {
  display: block;
  height: 15rem;
  max-height: 70vh;
}

.notification-inner-container {
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding: 0.5rem;
  padding-left: 1rem;
  cursor: pointer;
}

.notification-inner-container:hover {
  background-color: rgb(240, 240, 240);
}

.notification-title-container {
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.notification-markallasread {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.25rem;
  border-bottom: 1px solid lightgray;
  color: rgb(95, 95, 95);
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: bold;
}

.notification-markallasread:hover {
  background-color: rgb(248, 248, 248);
}

.notification-loading-container {
  display: flex;
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  align-items: center;
}

.notification-uptodate {
  display: flex;
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .notification-uptodate {
    font-size: 0.9rem;
  }
}

.input-container {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 100%;
  font-family: "Montserrat";
}

.input-container::-webkit-input-placeholder {
  color: #b1b1b1;
}

.input-container:-ms-input-placeholder {
  color: #b1b1b1;
}

.input-container::placeholder {
  color: #b1b1b1;
}
.footer-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: var(--tertiary-color);
  align-items: center;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
  font-weight: bold;
}

.footer-links {
  color: var(--tertiary-text-color);
}

@media screen and (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .footer-container a {
    margin-bottom: 0.5rem;
  }
}

.modal-size {
  min-width: calc(100vw - var(--navbar-width));
  left: var(--navbar-width);
}

@media screen and (max-width: 1200px) {
  .modal-size {
    left: 0;
    width: 100vw;
  }
}

.modal-title {
  font-weight: bolder;
  font-size: 2.5rem;
}

@media screen and (max-width: 800px) {
  .modal-title {
    font-size: 1.8rem;
  }
}

.modal-title-container {
  margin-bottom: 1rem;
}

.modal-background {
  position: fixed;
  z-index: 1000;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
}

.modal-cross-container {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  -webkit-filter: drop-shadow(10px, rgb(0, 0, 0));
          filter: drop-shadow(10px, rgb(0, 0, 0));
}

.modal-cross-container .fa-times {
  color: black;
  font-size: 1.75rem;
}

.modal-cross-container:hover .fa-times {
  -webkit-filter: drop-shadow(0px 0px 5px rgb(117, 117, 117));
          filter: drop-shadow(0px 0px 5px rgb(117, 117, 117));
  transition: 0.2s;
}

.modal-btn {
  font-size: 2rem;
  font-weight: 100;
  flex: 1 1;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: rgb(0, 165, 0);
  margin: 0;
  margin-top: 20px;
  cursor: pointer;
  font-family: "Montserrat";
  transition: background-color 500ms;
}

.modal-btn-red {
  background-color: red;
}

.modal-btn-loading {
  cursor: progress;
  background-color: rgb(139, 139, 139);
}

.modal-btn-disabled {
  cursor: default;
  background-color: rgb(139, 139, 139);
}

.user-modal-inner {
  z-index: 1001;
  min-width: 20%;
  max-width: 90%;
  padding: 50px;
  background-color: white;
  color: black;
  border-radius: 25px;
  position: relative;
}

.modal-user-table {
  border-spacing: 1rem;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.modal-user-table thead tr th {
  padding-bottom: 1rem;
  font-size: 1.4rem;
}

.modal-user-table td {
  padding-right: 1vmin;
}

.modal-user-table td:last-child {
  padding-right: 0px;
}

.user-modal-input {
  height: 2.4rem;
  border: 1px solid black;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.2rem;
}
.user-modal-inner {
  z-index: 1001;
  min-width: 20%;
  max-width: 90%;
  padding: 50px;
  background-color: white;
  color: black;
  border-radius: 25px;
  position: relative;
}

.modal-user-table {
  border-spacing: 1rem;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.modal-user-table thead tr th {
  padding-bottom: 1rem;
  font-size: 1.4rem;
}

.modal-user-table td {
  padding-right: 1vmin;
}

.modal-user-table td:last-child {
  padding-right: 0px;
}

.user-modal-input {
  height: 2.4rem;
  border: 1px solid black;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.2rem;
}
.btn {
  font-size: 2rem;
  font-weight: 100;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background-color: rgb(0, 165, 0);
  margin: 0;
  margin-bottom: 20px;
  cursor: pointer;
  font-family: "Montserrat";
  transition: background-color 500ms;
}

.btn-red {
  background-color: red;
}

.btn-loading {
  background-color: rgb(139, 139, 139) !important;
}

.btn-disabled {
  cursor: default;
  background-color: rgb(139, 139, 139);
}

.btn-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.inner-container-club {
  width: 80%;
  margin: auto;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

@media screen and (max-width: 450px) {
  .inner-container-club {
    width: 90% !important;
  }
}

.inner-container-club > h1 {
  margin-bottom: 1rem;
}

.branches-container {
  float: left;
  width: 100%;
  text-align: left;
}

.branch-title {
  font-size: 2rem;
}

.branch-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--primary-color);
  text-align: center;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}

.branch-container:hover {
  -webkit-filter: drop-shadow(0px 0px 5px rgb(117, 117, 117));
          filter: drop-shadow(0px 0px 5px rgb(117, 117, 117));
}

.club-name-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.club-settings-container {
  margin-left: 1rem;
  border: 2px solid rgb(69, 69, 69);
  color: rgb(69, 69, 69);
  border-radius: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}

.club-settings-container:hover {
  -webkit-filter: drop-shadow(0px 0px 5px rgb(185, 185, 185));
          filter: drop-shadow(0px 0px 5px rgb(185, 185, 185));
}

.no-branches-available {
  display: flex;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 10rem;
  font-size: 1.2rem;
}

.br-padding-container {
  display: flex;
  flex-direction: column;
}

.cs-navbar {
  height: 100vh;
  position: fixed;
  background-color: #2c2a31;
  padding-top: 1rem;
}

.inner-container-club {
  width: 80%;
  margin: auto;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.inner-container-club > h1 {
  margin-bottom: 1rem;
}

.big-banner-container {
  object-fit: cover;
  width: 100%;
  height: 30vmin;
  margin-bottom: 2rem;
  background-color: rgb(223, 223, 223);
  position: relative;
}

.big-banner-container > .club-logo-big {
  position: absolute;
  top: 15%;
  left: 5%;
  height: 70%;
}

.big-banner-container > .club-banner-big {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.branches-container {
  float: left;
  width: 100%;
  text-align: left;
}

.branch-title {
  font-size: 2rem;
}

.branch-grid {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .branch-grid {
    grid-gap: 2rem;
    gap: 2rem;
    grid-gap: 1rem !important;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 410px) {
  .branch-grid {
    grid-gap: 2rem;
    gap: 2rem;
    grid-gap: 1rem !important;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.branch-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--primary-color);
  text-align: center;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}

.branch-container:hover {
  -webkit-filter: drop-shadow(0px 0px 5px rgb(117, 117, 117));
          filter: drop-shadow(0px 0px 5px rgb(117, 117, 117));
}

.club-name-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.club-settings-container {
  margin-left: 1rem;
  border: 2px solid rgb(69, 69, 69);
  color: rgb(69, 69, 69);
  border-radius: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}

.club-settings-container:hover {
  -webkit-filter: drop-shadow(0px 0px 5px rgb(185, 185, 185));
          filter: drop-shadow(0px 0px 5px rgb(185, 185, 185));
}

.no-branches-available {
  display: flex;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 10rem;
  font-size: 1.2rem;
}

.br-padding-container {
  display: flex;
  flex-direction: column;
}

.no-courses-container {
  display: flex;
  width: 100%;
  margin-bottom: 6rem;
  margin-top: 6rem;
  text-align: center;
  justify-content: center;
  font-size: 1.3rem;
}

.course-container {
  display: flex;
  position: relative;
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;
  border: 1px solid darkgray;
  font-family: "Montserrat";
}

.course-title-container {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.4rem;
}

.course-img {
  color: rgb(194, 194, 194);
  min-height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
}

.course-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
}

.course-text-container {
  font-weight: bold;
  padding-top: 0.5rem;
}

.normal-weight {
  font-weight: normal;
}

.course-book-btn {
  display: inline-block;
  font-size: 1.2rem;
  padding: 1rem;
  background-color: rgb(144, 207, 100);
  color: white;
  cursor: pointer;
  width: auto;
  border: none;
}

.course-book-btn-gray {
  background-color: gray;
}

.course-info-inner-container {
  margin-bottom: 1rem;
}

.course-edit-icons {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}

.course-edit-icons .course-settings-icon {
  font-size: 1.5rem;
  margin-left: 10px;
}

.grayscale {
  -webkit-filter: grayscale(1) opacity(0.6);
          filter: grayscale(1) opacity(0.6);
}

.course-booking-detail-btn {
  display: inline-block;
  font-size: 1.2rem;
  padding: 1rem;
  background-color: rgb(100, 146, 207);
  color: white;
  cursor: pointer;
  border: none;
}

.inner-course-container {
  display: flex;
}

@media screen and (max-width: 600px) {
  .course-container {
    flex-direction: column;
  }
  .course-img {
    width: 100% !important;
    max-height: 5rem !important;
    min-height: 0 !important;
    width: auto;
    margin-bottom: 0.5rem;
  }
  .course-info-container {
    padding: 0 !important;
  }

  .course-title-container {
    margin-bottom: 0.2rem !important;
  }

  .course-book-btn {
    display: block !important;
    width: 100%;
    text-align: center;
  }

  .course-booking-detail-btn {
    display: block !important;
    width: 100%;
    text-align: center !important;
  }

  .inner-course-container-editable {
    margin-top: 2.3rem;
  }

  .inner-course-container {
    flex-direction: column;
  }
}

.title-container {
  margin-bottom: 1rem;
}

@media screen and (max-width: 840px) {
  .title-container h1 {
    font-size: 1.7rem;
  }
}

.input-container-search {
  display: flex;

  border-radius: 2px;
  margin-right: 10px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 100%;
}

.input-container-search input {
  width: 100%;
  height: 100%;
  padding: 8px 20px;
  outline: none;
  border: none;
  font-size: 18px;
}

.input-container-search .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: rgb(54, 54, 54);
}

.input-container-search::-webkit-input-placeholder {
  color: #b1b1b1;
}

.input-container-search:-ms-input-placeholder {
  color: #b1b1b1;
}

.input-container-search::placeholder {
  color: #b1b1b1;
}

.single-course-main-container {
  display: flex;
}

.single-course-img {
  color: rgb(194, 194, 194);
  min-height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
  margin-right: 1rem;
}

@media screen and (max-width: 720px) {
  .single-course-img {
    display: block !important;
    width: 100%;
    min-height: 0 !important;
    max-height: 10rem !important;
    margin-bottom: 0.6rem;
  }

  .single-course-main-container {
    flex-direction: column;
  }
}

.back-container {
    color: rgb(0, 0, 46);
    text-decoration: none;
}

.back-container div {
    margin-bottom: 1rem;
}

.back-element-icon {
    margin-right: 0.5rem;
}
.subtitle-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.subtitle-container h3 {
  margin-right: 0.2rem;
}

.info-icon-container {
  font-size: 0.9rem;
  cursor: pointer;
  color: rgb(56, 56, 56);
}

.info-text-container {
  position: absolute;
  display: none;
  align-items: center;
  -webkit-transform: translate(16px, -56%);
          transform: translate(16px, -56%);
  max-width: 50vw;
}

.info-text-inner-container {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  padding: 1.25rem;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
}

.info-text-triangle {
  width: 0px;
  height: 0px;

  border-top: 10px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--primary-color);
  z-index: 1;
}

.info-icon-container:hover .info-text-container {
  display: flex;
}

.info-container {
  display: flex;
  background-color: rgb(238, 238, 238);
  padding: 0.6rem;
  border-radius: 10px;
  /* color: white; */
  margin-bottom: 1rem;
}

.info-circle-icon-container {
  background-color: white;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.info-icon {
  color: #ffd166;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.info-inner-container {
  padding-top: 2px;
}

.mollie-testmode-container {
  padding: 1rem;
  margin-bottom: 2rem;
  border: 1px solid rgb(138, 138, 0);
  color: rgb(68, 68, 0);
  background-color: rgb(255, 225, 133);
  border-radius: 4px;
  line-height: 1.5em;
}

.mollie-testmode-title {
  font-weight: 600;
}

.accept-container {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.accept-container-checkbox {
  margin-right: 0.5rem;
  margin-top: 3px;
}

.profile-location-container {
  display: flex;
}

.profile-location-container input:last-child {
  margin-right: 0px;
}

.zipcode-input {
  flex: 1 1;
}

.street-input {
  flex: 4 1;
}

.city-input {
  flex: 4 1;
}

.house-number-input {
  flex: 1 1;
}

.cs-main-container {
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
}

.cs-inner-container {
  padding-left: var(--navbar-width);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

@media screen and (max-width: 1200px) {
  .cs-inner-container {
    padding-left: 0px;
  }
}

.cs-navbar {
  height: 100vh;
  position: fixed;
  background-color: #2c2a31;
  padding-top: 1rem;
}

.upload-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.upload-logo-container {
  display: flex;
  width: 20%;
  box-shadow: 0px 0px 10px rgb(69, 69, 69);
  align-items: center;
}

.upload-logo-container .background-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: rgb(194, 194, 194);
}

.upload-logo-container > img {
  width: 100%;
}

.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1rem;
  border-top: 1rem solid rgb(193, 202, 206);
  box-shadow: 0px 0px 10px rgb(69, 69, 69);
}

.image-upper-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: space-evenly;
  color: rgb(163, 163, 163);
  padding: 0.75rem;
  cursor: pointer;
}

.image-upper-container .image-icon {
  color: rgb(194, 194, 194);
}

.image-upper-container .progress-bar {
  width: 100%;
}

.image-lower-container {
  display: flex;
  flex: 1 1;
  background-color: rgb(219, 219, 219);
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.image-input-container {
}

.delete-button {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 2rem;
  background-color: #ff3232;
  color: white;
  font-size: 1.2rem;
}

.upload-container {
}

.fileInput {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switch-setting {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: .75rem;
  font-size: 1.2rem;
}

.switch-container {
  margin-right: 1rem;
}

/* .user-table {
  width: 100%;
  margin-bottom: 20px;
  color: white;
}

@media screen and (max-width: 840px) {
  .user-table tbody td {
    padding-left: 0.4rem;
  }
}

.user-table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

@media screen and (max-width: 840px) {
  .user-table th {
    font-size: 0.9rem;
  }
}

.user-table tbody tr {
  background-color: #313131;
  cursor: pointer;
}

.user-table tbody tr:nth-child(2n + 1) {
  background-color: #474747;
}

.user-table tbody tr:hover {
  filter: brightness(1.2);
}

.user-table thead tr {
  background-color: #2a2a2a;
} */

.action-btn-container {
  position: relative;
  padding: 0px;
  /* padding-top: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn {
  outline: none;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 20px;
  cursor: pointer;
}

.loading-table-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.subscreen-branch-inner-container {
  height: 100%;
}

.branches-title-container {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
}

.branch-subtitle-container {
  margin-bottom: 0.5rem;
}

.confirm-modal-inner {
  z-index: 1001;
  min-width: 20%;
  max-width: 90%;
  padding: 50px;
  background-color: white;
  color: black;
  border-radius: 25px;
  position: relative;
}

.modal-btn-container {
  display: flex;
  flex-direction: row;
}
.modal-btn-container button {
  margin-right: 1rem;
}

.modal-btn-container button:last-child {
  margin-right: 0px;
}
.branch-modal-inner {
  z-index: 1001;
  min-width: 20%;
  max-width: 90vmin;
  padding: 50px;
  background-color: white;
  color: black;
  border-radius: 25px;
  position: relative;
}

.branch-title {
  font-weight: 100;
  font-family: "Montserrat";
}

.branch-text {
  font-weight: 100;
  font-family: "Montserrat";
  font-size: 0.8rem;
}



.user-table {
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.user-table td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
  cursor: pointer;
  padding-right: 0.5rem;
}

.user-table th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
}

.user-table td:first-child,
.user-table th:first-child {
  padding-left: 1rem;
}

.user-table td:last-child,
.user-table th:last-child {
  padding-right: 1rem;
}

.user-table-roles-inner-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* .user-table tbody td {
  padding-left: 1.4rem;
  padding-top: 10px;
  padding-bottom: 10px;
} */

@media screen and (max-width: 840px) {
  /* .user-table tbody td {
    padding-left: 0.4rem;
  } */
}

/* .user-table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
} */

@media screen and (max-width: 840px) {
  /* .user-table th {
    font-size: 0.9rem;
  } */
}

/* .user-table tbody tr {
  background-color: #313131;
  cursor: pointer;
}

.user-table tbody tr:nth-child(2n + 1) {
  background-color: #474747;
}

.user-table tbody tr:hover {
  filter: brightness(1.2);
}

.user-table thead tr {
  background-color: #2a2a2a;
} */

.action-btn-container {
  position: relative;
  padding: 0px;
  /* padding-top: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn {
  outline: none;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 20px;
  cursor: pointer;
}

.loading-table-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media screen and (max-width: 960px) {
  .user-table-roles {
    display: none;
  }
  .user-table-status {
    display: none;
  }
  .user-table-rights {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .user-table-email {
    display: none;
  }
}

.user-table-membershipNumber-title:before {
  content: "Mitgliedsnummer";
}

@media screen and (max-width: 1000px) {
  .user-table-membershipNumber-title:before {
    content: "#";
  }
}

.br-main-container {
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
}

.br-inner-container {
  /* margin-left: var(--navbar-width); */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.booking-date-container {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #3f3f3f;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.booking-date-inner-container {
  display: flex;
  flex-direction: row;
}

.booking-date-text-container {
  margin-right: 1rem;
}

.booking-date-icon-container {
  margin-right: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.table-time-container {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  height: 100%;
  background-color: white;
  width: 5rem;
}

.table-time-container:nth-child(2) {
  border-left: 1px solid rgb(168, 168, 168);
}
.table-time-container-last {
  border-right: 1px solid rgb(168, 168, 168);
}

.table-title-container {
  display: flex;
  justify-content: space-between;
  background-color: #242424;
  height: 3rem;
  align-items: center;
  color: white;
  font-size: 1.4rem;
  border: none;
}

.table-title-time-container {
  justify-content: center;
}

.table-title-container .arrow-container {
  margin-right: 1rem;
  margin-left: 1rem;
  width: 1rem;
  cursor: pointer;
}

.table-time-inner-container {
  display: flex;
  flex: 1 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(168, 168, 168);
  /* border-left: 1px solid rgb(168, 168, 168); */
}

.booking-table {
  position: relative;
  z-index: 0;
}

.table-column-container {
  position: relative;
  display: flex;
  height: 900px;
  background-image: repeating-linear-gradient(
    45deg,
    #efefef,
    #efefef 22px,
    white 22px,
    white 44px
  );
  z-index: 0;
}

.table-object-main-container:nth-child(3) {
  border-left: 1px solid rgb(168, 168, 168);
}

.table-absolute-container {
  position: absolute;
  width: 100%;
  height: 50%;
  z-index: 1;
  background-color: rgba(100, 100, 100, 0.5);
}

.booking-table-head {
  position: -webkit-sticky;
  position: sticky;
  top: var(--header-height);
  /* top: 0; */
  z-index: 1;
}

.table-title-main-container {
  display: flex;
}

.table-object-container {
  flex: 1 1;
  height: 100%;
  border-right: 1px solid rgb(168, 168, 168);
  /* border-bottom: 1px solid rgb(168, 168, 168); */
  display: flex;
  flex-flow: column;
}

.table-object-container:nth-child(2) {
  border-left: 1px solid rgb(168, 168, 168);
}

.table-column-container
  .table-time-container:last-of-type
  .table-time-inner-container {
  border-left: none;
}

@media screen and (max-width: 840px) {
  .table-secondTimeContainer {
    display: none;
  }
}

.booking-main-container {
  background-color: white;
  cursor: pointer;
  border-bottom: 1px solid rgb(168, 168, 168);
}

.booking-main-inner-container {
  padding-left: 0.5rem;
  padding-top: 2px;
  font-size: 0.6rem;
  font-family: "Montserrat";
  color: rgb(24, 24, 24);
}

.booking-bookable {
}

.booking-bookable:hover {
  border: 2px solid black;
}

.booking-booked {
  border: none;
  border-left: 0.25rem solid rgb(0, 245, 151);
  border-bottom: 1px solid rgb(168, 168, 168);
  background-color: rgb(206, 255, 247);
}

.booking-disabled {
  background-color: rgb(207, 207, 207);
  cursor: default;
}

.booking-main-container:nth-child(2n) {
}

.branch-small-label {
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.book-modal-input {
  /* flex: 1; */
  width: 100%;
  margin-top: 1rem;
  padding: 10px;
  outline: none;
  border: transparent;
  font-family: "Montserrat";
  font-size: 1.5rem;
}

.book-modal-input:hover {
  box-shadow: 0 0 10px 10px rgb(0 0 0 / 10%);
}

.book-modal-table-list {
  flex: 1 1;
  width: 100%;
  font-size: 1.25rem;
}

.book-modal-table-list tbody tr td:last-child {
  font-weight: bold;
}


.member-container {
  padding: 1rem;
  padding-bottom: 2rem;
  border: 1px solid darkgray;
  margin-bottom: 2rem;
}

.member-align-container {
  display: flex;
  width: 100%;
  margin-top: 2rem;
}

.member-align-container div:first-child {
  border-right: 1px solid rgb(228, 228, 228);
}

.member-inner-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.member-login-btn {
  margin-top: 1rem;
  border: 1px solid var(--primary-color) !important;
  border-radius: 20px;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--primary-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .member-align-container {
    flex-direction: column;
  }

  .member-align-container div:first-child {
    border-right: none;
  }

  .member-inner-container:first-child {
    margin-bottom: 1.5rem;
  }

  .member-login-btn {
    margin-top: 0.5rem;
  }
}

.modal-btn-green {
  background-color: rgb(0, 165, 0);
}

.message-container {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1.5rem;
}

.message-error {
  background-color: rgba(255, 0, 0, 0.25);
  border-left: solid red 7px;
}

.message-success {
  background-color: rgba(0, 255, 0, 0.25);
  border-left: solid green 7px;
}

.modal-btn-red {
  background-color: red;
}

.course-date-input-container {
  display: flex;
}

.course-date-input-container input:last-child {
  margin-right: 0px;
}

.course-edit-container {
  width: 100%;
}

.course-participant {
  margin-top: 2rem;
}

.course-print-icon {
  margin-left: 0.5rem;
  cursor: pointer;
  color: black;
}

.two-input-container *:last-child {
  margin-right: 0px !important;
}

.adopt-settings-container {
  margin-bottom: 1rem;
}

.multiselector-container {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  z-index: 0;
  font-family: "Montserrat";
  font-weight: normal;
}

.multiselector-element-container {
  display: inline-block;
  border-radius: 1rem;
  padding: 4px;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 4px;
  border: 2px solid rgb(238, 92, 92);
  flex-direction: row;
  margin-top: 1px;
  margin-bottom: 1px;
}

.multiselector-add-element-container {
  border: 2px solid rgb(133, 133, 133);
  color: rgb(133, 133, 133);
  cursor: pointer;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 0px;
  border-radius: 50%;
}

.multiselector-element-container > .point {
  float: left;
  width: 1rem;
  height: 1rem;
  background-color: rgb(238, 92, 92);
  border-radius: 50%;
  margin-right: 4px;
}

.point > .cross-container {
  display: flex;
  flex: 1 1;
  height: 100%;
  color: white;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  opacity: 0%;
}

.point:hover > .cross-container {
  opacity: 100%;
}

.multiselector-add-container {
  display: none;
  position: absolute;
  background-color: #313131;
  border-radius: 0.5rem;
  padding: 1rem;
  min-width: 10rem;
  -webkit-transform: translate(calc(-50% + 120px), 30px);
          transform: translate(calc(-50% + 120px), 30px);
  max-height: 200px;
  z-index: 2;
  font-family: "Montserrat";
  font-weight: normal;
}

.multiselector-show-add-container {
  display: flex;
  flex-direction: column;
}

.multiselector-add-text-container {
  background-color: #242424;
  border-radius: 6px;
  flex-direction: row;
  display: flex;
  margin-bottom: 6px;
}

.multiselector-add-text-container .icon-container {
  display: flex;
  /* flex: 1; */
  padding-right: 6px;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  float: right;
  cursor: default;
  font-size: 1.2rem;
  color: rgb(88, 88, 88);
}

.multiselector-add-text-container > .text-input-container > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  padding: 0.5rem;
  font-size: 1.01rem;
}

.multiselector-select-element-container {
  display: flex;
  justify-content: flex-start;
  color: rgb(231, 231, 231);
  padding: 0.75rem;
  border-radius: 6px;
}

.multiselector-select-element-container:hover {
  background-color: #3d3d3d;
}

.multiselector-select-element-container .point {
  float: left;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: rgb(238, 92, 92);
  margin-right: 0.5rem;
}

.multiselector-scroll-container {
  overflow-y: auto;
}

.multiselector-scroll-container::-webkit-scrollbar {
  width: 0.25rem;
}

.multiselector-scroll-container::-webkit-scrollbar-track {
  background: #1e1e24;
}

.multiselector-scroll-container::-webkit-scrollbar-thumb {
  background: #686868;
}

.calendar-table {
  width: 100%;
  text-align: center;
  table-layout: fixed;
  margin-bottom: 2rem;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.calendar-table td,
.calendar-table th {
  height: 2.5rem;
}

.calendar-table td {
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
}

.calendar-table td:hover {
  /* box-sizing: border-box; */
  /* border: 1px solid black; */
}

.day-no-focus {
  color: rgb(165, 165, 165);
}

.calendar-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #3b3b3b;
}

.calendar-date {
  font-size: 1.2rem;
  font-weight: bold;
}

.arrow-container {
  display: flex;
  flex-direction: row;
}

.arrow-container div {
  margin-right: 1rem;
  cursor: pointer;
}

.day-circle {
  display: flex;
  border-radius: 50%;
  background-color: rgb(141, 209, 255);
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.day-outer-circle {
  display: flex;
  border-radius: 50%;
  border: 2px solid rgb(141, 209, 255);
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.day-circle-low {
  background-color: rgb(216, 240, 255);
}

.calendar-table td:hover .day-circle-hover {
  display: flex;
  border-radius: 50%;
  background-color: rgb(210, 237, 255);
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.day-today {
  color: rgb(0, 14, 95);
  font-weight: bold;
}

.course-date-container {
  border: 1px solid black;
  margin-bottom: 1rem;
  padding: 1rem;
}

.more-info-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.more-info-inner-container {
  margin-right: 0.5rem;
}

.show-more-info-container {
  margin-top: 1rem;
}
.text-checkbox-container {
  display: inline-block;
  margin-right: 2rem;
}

.subtitle-two-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.subtitle-two-container span {
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-size: 1.4rem;
  margin-right: 0.2rem;
  text-decoration: underline;
}

.info-icon-container {
  font-size: 0.9rem;
  cursor: pointer;
  color: rgb(56, 56, 56);
}

.info-text-container {
  position: absolute;
  display: none;
  align-items: center;
  -webkit-transform: translate(16px, -56%);
          transform: translate(16px, -56%);
  max-width: 50vw;
}

.info-text-inner-container {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  padding: 1.25rem;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
}

.info-text-triangle {
  width: 0px;
  height: 0px;

  border-top: 10px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--primary-color);
  z-index: 1;
}

.info-icon-container:hover .info-text-container {
  display: flex;
}

.br-navbar {
  position: fixed;
  background-color: var(--primary-color);
  /* padding-top: 1rem; */
}

.create-course-modal::-webkit-scrollbar {
  width: 0rem;
}

.create-course-modal::-webkit-scrollbar-track {
  background: #1e1e24;
}

.create-course-modal::-webkit-scrollbar-thumb {
  background: #686868;
}

.modal-btn-container {
  display: flex;
  flex-direction: row;
}
.modal-btn-container button {
  margin-right: 1rem;
}

.modal-btn-container button:last-child {
  margin-right: 0px;
}

.course-time-input-container {
  display: flex;
}

.course-time-input-container input:last-child {
  margin-right: 0px;
}

.course-input {
  font-family: "Montserrat";
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 100%;
}

.course-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.course-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.course-input::placeholder {
  color: #b1b1b1;
}

.expireDate-container {
  margin-right: 0px;
}

/* TABLE*/
.table-price {
  margin-bottom: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.table-price td {
  border-top: 1px solid var(--border-color);
  padding-right: 1rem;
  height: 3rem;
}

.table-price td:first-child,
.table-price th:first-child {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 1px;
}

.table-price th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
}

.to-text-container {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  align-items: center;
}

.multiple-dates-edit-btn {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  background-color: rgb(89, 141, 238);
  color: white;
  margin-left: 0.5rem;
  cursor: pointer;
}

.course-add-btn {
}

.course-remove-btn {
  background-color: rgb(238, 94, 94);
}

.course-type-container {
  position: relative;
  margin-bottom: 1rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.course-type-inner-container {
  position: relative;
  border: 1px solid rgb(195, 195, 195);
  height: 2.75rem;
  border-radius: 50px;
  display: flex;
  text-align: center;
}
@media screen and (max-width: 450px) {
  .course-type-container {
    font-size: 0.8rem;
  }
}

.course-type-inner-container div {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  border-radius: 50px;
  color: rgb(80, 80, 80);
  transition: color 0.4s ease-in;
  -webkit-transition: color 0.4s ease-in;
}

.course-type-selected {
  color: var(--primary-text-color) !important;
}

.course-type-background {
  position: absolute;
  background-color: var(--primary-color);
  width: 33.3%;
  height: 100%;
  border-radius: 50px;
  transition: 0.4s ease-out;
}

.course-type-background:hover {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.course-type-background-1 {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.course-type-background-2 {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.course-type-background-3 {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}

.course-date-input-container {
  display: flex;
}

.course-date-input-container input:last-child {
  margin-right: 0px;
}

.course-edit-container {
  width: 100%;
}

.course-title-print-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.course-print-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}

.print-container {
  display: none;
  padding: 1rem;
}

.print-title-container {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.2rem;
}

.print-title {
  text-align: center;
  padding-bottom: 1rem;
}

@media print {
  .print-container {
    display: block;
  }
}

.email-copy-container {
  display: inline-block;
  border: 1px solid rgb(200, 200, 200);
  padding: 0.5rem;
  border-radius: 0px;
  color: rgb(56, 56, 56);
  cursor: pointer;
  transition: 0.2s;
  margin-bottom: 1rem;
}

.email-copy-container-copied {
  background-color: rgb(240, 240, 240);
}

.email-copy-check-container {
  color: rgb(153, 153, 153);
}

.email-copy-container-copied .email-copy-check-container {
  color: green;
}

.email-copy-check-container {
  margin-left: 10px;
}

@media screen and (max-width: 480px) {
  .course-participants-more-information-switch {
    display: none;
  }
}

.earnings-main-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.earnings-main-container .earnings-inner-container {
  margin-right: 2rem;
  flex: 1 1;
  padding: 1rem;
  box-shadow: 0 0 12px 5px rgb(0 0 0 / 10%);
  border-radius: 12px;
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: rgb(54, 54, 54);
}

.earnings-title {
  font-size: 1.3rem;
  border-bottom: 1px solid lightgray;
}

.earnings-main-container div:last-child {
  margin-right: 0;
}

.earnings-main-container .earnings-price {
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 600px) {
  .earnings-main-container {
    flex-direction: column;
  }

  .earnings-main-container .earnings-inner-container {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.table-course-users {
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.table-course-users td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
  cursor: pointer;
  padding-right: 0.5rem;
}

.table-course-users td:first-child,
.table-course-users th:first-child {
  padding-left: 1rem;
}

.table-course-users td:last-child,
.table-course-users th:last-child {
  padding-right: 1rem;
}

.table-course-users th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
}

@media screen and (max-width: 820px) {
  .table-course-users-date {
    display: none;
  }
}

@media screen and (max-width: 620px) {
  .table-course-users-email {
    display: none;
  }
}

.payment-status {
  display: inline-block;
  text-transform: uppercase;
  background-color: aquamarine;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  color: white;
  font-size: 0.71rem;
  border-radius: 4px;
  transition: 0.2s;
}

.table-course-users {
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.table-course-user-printmode {
  border-collapse: separate;
  font-size: 0.9rem;
}

.table-course-user-printmode td,
.table-course-user-printmode th {
  padding-right: 1rem;
}

.table-course-users td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
  cursor: pointer;
  padding-right: 0.5rem;
}

.table-course-users td:first-child,
.table-course-users th:first-child {
  padding-left: 1rem;
}

.table-course-users td:last-child,
.table-course-users th:last-child {
  padding-right: 1rem;
}

.table-course-users th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
}

@media screen and (max-width: 750px) {
  .table-course-users-address {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .table-course-users-birthdate {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .table-course-users-phonenumber {
    display: none;
  }
  .table-course-users {
    font-size: 0.8rem;
  }
  .table-course-users th {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 350px) {
  .table-course-users-email-print {
    display: none;
  }
}

.course-participants-type-container {
  position: relative;
  margin-bottom: 1rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.course-participants-type-inner-container {
  position: relative;
  border: 1px solid rgb(195, 195, 195);
  height: 2.75rem;
  border-radius: 50px;
  display: flex;
}

.course-participants-type-inner-container div {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  border-radius: 50px;
  color: rgb(80, 80, 80);
  transition: color 0.4s ease-in;
  -webkit-transition: color 0.4s ease-in;
}

.course-participants-type-selected {
  color: var(--primary-text-color) !important;
}

.course-participants-type-background {
  position: absolute;
  background-color: var(--primary-color);
  width: 50%;
  height: 100%;
  border-radius: 50px;
  transition: 0.4s ease-out;
}

.course-participants-type-background:hover {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.course-participants-type-background-1 {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.course-participants-type-background-2 {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.course-participants-type-background-3 {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}

.text-area-input-container {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 100%;
  font-family: "Montserrat";
}

.text-area-input-container::-webkit-input-placeholder {
  color: #b1b1b1;
}

.text-area-input-container:-ms-input-placeholder {
  color: #b1b1b1;
}

.text-area-input-container::placeholder {
  color: #b1b1b1;
}
.qrCode-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.confirmation-container-status {
  flex: 1 1;
}

.main-confirmation-container {
  margin-top: 2rem;
  display: flex;
}

.confirmation-title {
  font-weight: bold;
  padding-right: 1rem;
}

.bookingData-loading-container {
  display: flex;
  flex-direction: column;
}

.bookingData-loading-spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.selected-member-container {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  font-size: 1.5rem;
}

.selected-member-container div {
  margin-right: 0.5rem;
}

.selected-member-container img {
  margin-right: 1rem;
}

.course-participants-type-container {
  position: relative;
  margin-bottom: 1rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.course-participants-type-inner-container {
  position: relative;
  border: 1px solid rgb(195, 195, 195);
  height: 2.75rem;
  border-radius: 50px;
  display: flex;
}

.course-participants-type-inner-container div {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  border-radius: 50px;
  color: rgb(80, 80, 80);
  transition: color 0.4s ease-in;
  -webkit-transition: color 0.4s ease-in;
}

.course-participants-type-selected {
  color: var(--primary-text-color) !important;
}

.course-participants-type-background {
  position: absolute;
  background-color: var(--primary-color);
  width: 50%;
  height: 100%;
  border-radius: 50px;
  transition: 0.4s ease-out;
}

.course-participants-type-background:hover {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.course-participants-type-background-1 {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.course-participants-type-background-2 {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.course-participants-type-background-3 {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}

.booking-information-table {
  width: 100%;
  margin-bottom: 1rem;
  color: rgb(26, 26, 26);
  border-spacing: 0px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.booking-information-table td:first-child {
  font-weight: bold;
  padding-right: 1rem;
  padding-left: 1rem;
}

.no-border td {
  border: none !important;
}

.booking-information-table td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
}

.save-courseBooking-btn {
  margin-top: 1rem;
  margin-bottom: 0;
}

.delete-courseBooking-btn {
  margin-bottom: 1rem !important;
  font-size: 2rem;
  font-weight: 100;
  flex: 1 1;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: red;
  margin: 0;
  margin-top: 20px;
  cursor: pointer;
  font-family: "Montserrat";
  transition: background-color 500ms;
}


.qrCode-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.confirmation-container-status {
  flex: 1 1;
}

.main-confirmation-container {
  margin-top: 2rem;
  display: flex;
}

.confirmation-title {
  font-weight: bold;
  padding-right: 1rem;
}

.courseBooking-course-info-container {
  display: flex;
  margin-bottom: 1rem;
  font-family: "Montserrat";
}

.courseBooking-course-info-container h2 {
  margin-bottom: 0.4rem;
}

@media screen and (max-width: 710px) {
  .courseBooking-course-info-container {
    flex-direction: column;
  }

  .courseBooking-course-info-container h2 {
    margin-bottom: 0.5rem;
    margin-top: 0rem;
  }
}

.cancellationContainer {
  margin-top: 2rem;
}

.course-booking-information-table {
  width: 100%;
  margin-bottom: 1rem;
  color: rgb(26, 26, 26);
  border-spacing: 0px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.course-booking-information-table td:first-child {
  font-weight: bold;
  padding-right: 1rem;
  padding-left: 1rem;
}

.no-border td {
  border: none !important;
}

.course-booking-information-table td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
}

.br-main-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  height: 100%;
}

.br-inner-container {
  padding-left: var(--navbar-width);
  width: 100%;
  height: 100%;
  transition: padding-left 300ms cubic-bezier(0, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
}

.menu-container {
  padding: 1rem;
  background-color: white;
  box-shadow: 0 0 10px 10px rgb(0 0 0 / 10%);
  border-radius: 2rem;
  position: fixed;
  top: 0.75rem;
  left: 0.75rem;
  cursor: pointer;
  /* z-index: 1; */
}

.br-padding-container {
  margin-top: var(--header-height);
  display: flex;
  flex: 1 1;
  /* z-index: -1; */
}

.menu-icon {
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 1200px) {
  .br-inner-container {
    padding-left: 0px;
  }

  .menu-icon {
    display: block;
  }
}

/* .br-navbar {
  position: fixed;
  background-color: #2c2a31;
} */

.navbar-objects {
  display: none;
}

.navbar-objects-selected {
  display: block;
}
.user-table {
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.user-table td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
  cursor: pointer;
  padding-right: 0.5rem;
}

.user-table th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
}

.user-table td:first-child,
.user-table th:first-child {
  padding-left: 1rem;
}

.user-table td:last-child,
.user-table th:last-child {
  padding-right: 1rem;
}

.user-table-roles-inner-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* .user-table tbody td {
  padding-left: 1.4rem;
  padding-top: 10px;
  padding-bottom: 10px;
} */

@media screen and (max-width: 840px) {
  /* .user-table tbody td {
    padding-left: 0.4rem;
  } */
}

/* .user-table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
} */

@media screen and (max-width: 840px) {
  /* .user-table th {
    font-size: 0.9rem;
  } */
}

/* .user-table tbody tr {
  background-color: #313131;
  cursor: pointer;
}

.user-table tbody tr:nth-child(2n + 1) {
  background-color: #474747;
}

.user-table tbody tr:hover {
  filter: brightness(1.2);
}

.user-table thead tr {
  background-color: #2a2a2a;
} */

.action-btn-container {
  position: relative;
  padding: 0px;
  /* padding-top: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn {
  outline: none;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 20px;
  cursor: pointer;
}

.loading-table-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media screen and (max-width: 960px) {
  .user-table-roles {
    display: none;
  }
  .user-table-status {
    display: none;
  }
  .user-table-rights {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .user-table-email {
    display: none;
  }
}

.user-table-membershipNumber-title:before {
  content: "Mitgliedsnummer";
}

@media screen and (max-width: 1000px) {
  .user-table-membershipNumber-title:before {
    content: "#";
  }
}

.mollie-connect-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 10px;
  background-color: rgb(127, 165, 255);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.font-mollie {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 3rem;
}

.connect-text {
  font-size: 2rem;
}

.mollie-loading-container {
  margin-left: 1rem;
}

.mollie-onboarding-container {
  display: flex;
  margin-bottom: 2rem;
}

@media screen and (max-width: 960px) {
  .mollie-onboarding-container {
    flex-direction: column;
  }
  .mollie-connect-container {
    margin-bottom: 1rem;
  }
}

.mollie-onboarding-info-container {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}

.mollie-onboarding-info-inner-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
  max-width: 30rem;
}

.mollie-onboarding-row {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
}

.mollie-onboarding-title {
  font-weight: bold;
}

.mollie-onboarding-value {
  text-align: right;
  justify-content: flex-end;
}

.title-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  margin-bottom: 1rem;
}

.lock-icon {
  cursor: pointer;
}

.lock-loading-container {
  margin-right: 0.5rem;
}

.subscreen-branch-inner-container {
  display: flex;
  flex: 1 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.subscreen-branch-inner-container::-webkit-scrollbar {
  width: 0.6rem;
}

.subscreen-branch-inner-container::-webkit-scrollbar-track {
  background: #c5c5c5;
}

.subscreen-branch-inner-container::-webkit-scrollbar-thumb {
  background: #8d8d8d;
}

.branches-title-container {
  margin-bottom: 1rem;
}

.branch-subtitle-container {
  margin-bottom: 0.5rem;
}


.module-grid-container {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.module-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 2rem;
  height: 3.2rem;
  width: 3.2rem;
  color: white;
  margin-right: 1rem;
}

.module-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Montserrat";
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.module-description-container {
  color: rgb(68, 68, 68);
  margin-bottom: 2rem;
}

.module-container {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  padding: 1rem;
  box-shadow: 0px 0px 10px rgb(69, 69, 69);
  /* overflow: hidden; */
}

.module-button-container {
  font-weight: 600;
  padding: 0.6rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: 200ms;
}

.module-button-activate-container {
  color: white;
  background-color: #1967d2;
}

.module-button-activate-container:hover {
  background-color: #3674ca;
}

.module-button-moreinfo-container {
  color: #979797;
  background-color: white;
}

.module-button-moreinfo-container:hover {
  color: #329adf;
  background-color: #e1f3fc;
}

.module-button-main-container {
  display: flex;
}

.module-title-info {
  padding: 0.2rem;
  margin-left: 0.4rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 1rem;
  font-size: 1rem;
}

.module-title-beta {
  background-color: mediumslateblue;
  color: white;
}

.module-title-planned {
  background-color: rgb(221, 131, 80);
  color: white;
}

.module-button-container-disabled {
  cursor: default;
  background-color: #979797;
}

.module-button-container-disabled:hover {
  background-color: #979797;
}

@media screen and (max-width: 840px) {
  .module-title-container {
    font-size: 1.2rem;
  }
}


.subscreen-branch-inner-container {
  display: flex;
  flex: 1 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.subscreen-branch-inner-container::-webkit-scrollbar {
  width: 0.6rem;
}

.subscreen-branch-inner-container::-webkit-scrollbar-track {
  background: #c5c5c5;
}

.subscreen-branch-inner-container::-webkit-scrollbar-thumb {
  background: #8d8d8d;
}

.branches-title-container {
  margin-bottom: 1rem;
}

.branch-subtitle-container {
  margin-bottom: 0.5rem;
}

.roles-priority-container {
  margin-bottom: 1rem;
}

.role-priority-container {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid gray;
  cursor: move;
}

.subscreen-branch-inner-container {
  display: flex;
  flex: 1 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.subscreen-branch-inner-container::-webkit-scrollbar {
  width: 0.6rem;
}

.subscreen-branch-inner-container::-webkit-scrollbar-track {
  background: #c5c5c5;
}

.subscreen-branch-inner-container::-webkit-scrollbar-thumb {
  background: #8d8d8d;
}

.branches-title-container {
  margin-bottom: 1rem;
}

.branch-subtitle-container {
  margin-bottom: 0.5rem;
}

.subscreen-branch-inner-container {
  display: flex;
  flex: 1 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.subscreen-branch-inner-container::-webkit-scrollbar {
  width: 0.6rem;
}

.subscreen-branch-inner-container::-webkit-scrollbar-track {
  background: #c5c5c5;
}

.subscreen-branch-inner-container::-webkit-scrollbar-thumb {
  background: #8d8d8d;
}

.branches-title-container {
  margin-bottom: 1rem;
}

.branch-subtitle-container {
  margin-bottom: 0.5rem;
}

.color-input {
  margin-bottom: 1rem;
  height: 64px;
  width: 64px;
}

.color-input > input {
  cursor: pointer;
  opacity: 0;
  display: block;
  height: 100%;
  width: 100%;
}

:root {
  --pricing-table-height: 2.2rem;
  --pricing-table-verticall-spacing: 0.5rem;
  --pricing-table-font-size: 1rem;
  --pricing-table-padding-left: 1rem;
  --pricing-table-font-family: "Montserrat";
}

.pricing-table {
  width: 100%;
  /* table-layout: fixed; */
}

.pricing-time-input {
  margin: 0;
  width: 100%;
  height: 2.2rem;
  height: var(--pricing-table-height);
  border: 1px solid darkgray;
  margin-bottom: 0.5rem;
  margin-bottom: var(--pricing-table-verticall-spacing);
  font-size: 1rem;
  font-size: var(--pricing-table-font-size);
  padding-left: 1rem;
  padding-left: var(--pricing-table-padding-left);
  font-family: "Montserrat";
  font-family: var(--pricing-table-font-family);
}

.pricing-price-input {
  margin: 0;
  width: 100%;
  height: 2.2rem;
  height: var(--pricing-table-height);
  border: 1px solid darkgray;
  margin-bottom: 0.5rem;
  margin-bottom: var(--pricing-table-verticall-spacing);
  font-size: 1rem;
  font-size: var(--pricing-table-font-size);
  padding-left: 1rem;
  padding-left: var(--pricing-table-padding-left);
  font-family: "Montserrat";
  font-family: var(--pricing-table-font-family);
}

.pricing-icon-container {
  display: flex;
  height: 2.2rem;
  height: var(--pricing-table-height);
  align-items: center;
  justify-content: center;
}

.pricing-icon-container .pricing-icon {
  cursor: pointer;
}

:root {
  --pricing-table-height: 2.2rem;
  --pricing-table-verticall-spacing: 0.5rem;
  --pricing-table-font-size: 1rem;
  --pricing-table-padding-left: 1rem;
  --pricing-table-font-family: "Montserrat";
}

.pricing-table {
  width: 100%;
  /* table-layout: fixed; */
}

.pricing-time-input {
  font-family: "Montserrat";
  margin: 0;
  width: 100%;
  height: 2.2rem;
  height: var(--pricing-table-height);
  border: 1px solid darkgray;
  margin-bottom: 0.5rem;
  margin-bottom: var(--pricing-table-verticall-spacing);
  font-size: 1rem;
  font-size: var(--pricing-table-font-size);
  padding-left: 1rem;
  padding-left: var(--pricing-table-padding-left);
  font-family: "Montserrat";
  font-family: var(--pricing-table-font-family);
}

.pricing-price-input {
  margin: 0;
  width: 100%;
  height: 2.2rem;
  height: var(--pricing-table-height);
  border: 1px solid darkgray;
  margin-bottom: 0.5rem;
  margin-bottom: var(--pricing-table-verticall-spacing);
  font-size: 1rem;
  font-size: var(--pricing-table-font-size);
  padding-left: 1rem;
  padding-left: var(--pricing-table-padding-left);
  font-family: "Montserrat";
  font-family: var(--pricing-table-font-family);
}

.pricing-icon-container {
  display: flex;
  height: 2.2rem;
  height: var(--pricing-table-height);
  align-items: center;
  justify-content: center;
}

.pricing-icon-container .pricing-icon {
  cursor: pointer;
}

#price-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#price-table td,
#price-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#price-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#price-table tr:hover {
  background-color: #ddd;
}

#price-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #282828;
  color: white;
}

.user-table {
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.user-table td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
  cursor: pointer;
  padding-right: 0.5rem;
}

.user-table th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
}

.user-table td:first-child,
.user-table th:first-child {
  padding-left: 1rem;
}

.user-table td:last-child,
.user-table th:last-child {
  padding-right: 1rem;
}

.user-table-roles-inner-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* .user-table tbody td {
  padding-left: 1.4rem;
  padding-top: 10px;
  padding-bottom: 10px;
} */

@media screen and (max-width: 840px) {
  /* .user-table tbody td {
    padding-left: 0.4rem;
  } */
}

/* .user-table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
} */

@media screen and (max-width: 840px) {
  /* .user-table th {
    font-size: 0.9rem;
  } */
}

/* .user-table tbody tr {
  background-color: #313131;
  cursor: pointer;
}

.user-table tbody tr:nth-child(2n + 1) {
  background-color: #474747;
}

.user-table tbody tr:hover {
  filter: brightness(1.2);
}

.user-table thead tr {
  background-color: #2a2a2a;
} */

.action-btn-container {
  position: relative;
  padding: 0px;
  /* padding-top: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn {
  outline: none;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 20px;
  cursor: pointer;
}

.loading-table-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media screen and (max-width: 960px) {
  .user-table-roles {
    display: none;
  }
  .user-table-status {
    display: none;
  }
  .user-table-rights {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .user-table-email {
    display: none;
  }
}

.user-table-membershipNumber-title:before {
  content: "Mitgliedsnummer";
}

@media screen and (max-width: 1000px) {
  .user-table-membershipNumber-title:before {
    content: "#";
  }
}

.main-info-screen h1  {
    margin-bottom: 1rem;
}

.main-info-screen h2  {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.main-info-screen h3 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}



