.confirm-modal-inner {
  z-index: 1001;
  min-width: 20%;
  max-width: 90%;
  padding: 50px;
  background-color: white;
  color: black;
  border-radius: 25px;
  position: relative;
}

.modal-btn-container {
  display: flex;
  flex-direction: row;
}
.modal-btn-container button {
  margin-right: 1rem;
}

.modal-btn-container button:last-child {
  margin-right: 0px;
}