.vert-navbar {
  /* height: calc(100vh - var(--header-height)); */
  width: var(--navbar-width);
  height: 100vh;
  position: fixed;
  left: 0;
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.vert-navbar-scrollable {
  /* height: 100%; */
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
}

@media screen and (max-width: 1200px) {
  .vert-navbar {
    transform: translate3d(calc(var(--navbar-width) * -1), 0, 0);
  }
}

.vert-navbar-scrollable::-webkit-scrollbar {
  width: 0.25rem;
}

.vert-navbar-scrollable::-webkit-scrollbar-track {
  background: #1e1e24;
}

.vert-navbar-scrollable::-webkit-scrollbar-thumb {
  background: #584e74;
}

.vert-navbar-mobile {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
}

.nav-background {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100%;
  z-index: -10;
  transition: background-color 300ms ease-out;
}

.show-nav-background {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
