.cs-main-container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}

.cs-inner-container {
  padding-left: var(--navbar-width);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

@media screen and (max-width: 1200px) {
  .cs-inner-container {
    padding-left: 0px;
  }
}
