.navbar-element {
  display: flex;
  flex-direction: row;
  color: var(--primary-text-color);
  font-size: 1.2rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1rem;
  /* border-bottom: 1px solid rgb(26, 25, 29); */
  cursor: pointer;
  text-decoration: none;
}

.navbar-element-selected {
  color: white;
  background-color: var(--secondary-color);
}

.navbar-element:last-child {
  border-bottom: none;
}

.navbar-element > .navbar-icon-container {
  margin-right: 1rem;
  min-width: 2rem;
}

.navbar-element-logo {
  font-weight: bold;
  font-family: "Montserrat";
}

.navbar-element > .navbar-icon-logo-container {
  margin-right: 0.75rem;
}

.navbar-create-element {
  justify-content: center;
  padding-right: 1rem;
}

.navbar-back-element {
  background-color: rgb(34, 34, 34);
  border-top: 1px solid rgb(50, 50, 50);
  justify-content: center;
  padding-right: 1rem;
  height: none;
}

.navbar-element-logo {
  flex: 0 0 auto;
  background-color: var(--tertiary-color);
  color: var(--tertiary-text-color);
  height: var(--header-height);
  /* border-bottom: 1px solid rgb(50, 50, 50); */
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.navbar-element-user {
  background-color: var(--user-element-color);
  /* border-bottom: 1px solid rgb(50, 50, 50); */
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-profile-pic {
  width: 4rem;
}

.navbar-element-user-textcontainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-element-user-logout {
  width: 80%;
  border: 1px solid white;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  padding: 0.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  text-align: center;
  background-color: transparent;
  transition: all 300ms ease;
}

.navbar-element-user-logout:hover {
  color: black;
  background-color: white;
}

.navbar-element-indented {
  padding-left: 4rem;
}

.name-spaceholder-container {
  display: flex;
}

.name-spaceholder {
  height: 1em;
  width: 4rem;
  background-color: var(--secondary-color);
  border-radius: 20px;
  margin-right: 0.5rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

.name-spaceholder-fName {
  width: 4rem;
}

.name-spaceholder-lName {
  width: 6rem;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.7;
  }
}
