.table-course-users {
  margin-top: 1rem;
  width: 100%;
  border-spacing: 0px;
  color: rgb(88, 88, 88);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.table-course-users td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
  cursor: pointer;
  padding-right: 0.5rem;
}

.table-course-users td:first-child,
.table-course-users th:first-child {
  padding-left: 1rem;
}

.table-course-users td:last-child,
.table-course-users th:last-child {
  padding-right: 1rem;
}

.table-course-users th {
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fbfbfb;
  padding-right: 0.5rem;
}

@media screen and (max-width: 820px) {
  .table-course-users-date {
    display: none;
  }
}

@media screen and (max-width: 620px) {
  .table-course-users-email {
    display: none;
  }
}
