.booking-information-table {
  width: 100%;
  margin-bottom: 1rem;
  color: rgb(26, 26, 26);
  border-spacing: 0px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.booking-information-table td:first-child {
  font-weight: bold;
  padding-right: 1rem;
  padding-left: 1rem;
}

.no-border td {
  border: none !important;
}

.booking-information-table td {
  border-top: 1px solid var(--border-color);
  height: 3rem;
}

.save-courseBooking-btn {
  margin-top: 1rem;
  margin-bottom: 0;
}

.delete-courseBooking-btn {
  margin-bottom: 1rem !important;
  font-size: 2rem;
  font-weight: 100;
  flex: 1;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: red;
  margin: 0;
  margin-top: 20px;
  cursor: pointer;
  font-family: "Montserrat";
  transition: background-color 500ms;
}
