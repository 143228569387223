.input-container-search {
  display: flex;

  border-radius: 2px;
  margin-right: 10px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 100%;
}

.input-container-search input {
  width: 100%;
  height: 100%;
  padding: 8px 20px;
  outline: none;
  border: none;
  font-size: 18px;
}

.input-container-search .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: rgb(54, 54, 54);
}

.input-container-search::placeholder {
  color: #b1b1b1;
}
