.br-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}

.br-inner-container {
  padding-left: var(--navbar-width);
  width: 100%;
  height: 100%;
  transition: padding-left 300ms cubic-bezier(0, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
}

.menu-container {
  padding: 1rem;
  background-color: white;
  box-shadow: 0 0 10px 10px rgb(0 0 0 / 10%);
  border-radius: 2rem;
  position: fixed;
  top: 0.75rem;
  left: 0.75rem;
  cursor: pointer;
  /* z-index: 1; */
}

.br-padding-container {
  margin-top: var(--header-height);
  display: flex;
  flex: 1;
  /* z-index: -1; */
}

.menu-icon {
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 1200px) {
  .br-inner-container {
    padding-left: 0px;
  }

  .menu-icon {
    display: block;
  }
}
