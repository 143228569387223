.course-container {
  display: flex;
  position: relative;
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;
  border: 1px solid darkgray;
  font-family: "Montserrat";
}

.course-title-container {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.4rem;
}

.course-img {
  color: rgb(194, 194, 194);
  min-height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
}

.course-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
}

.course-text-container {
  font-weight: bold;
  padding-top: 0.5rem;
}

.normal-weight {
  font-weight: normal;
}

.course-book-btn {
  display: inline-block;
  font-size: 1.2rem;
  padding: 1rem;
  background-color: rgb(144, 207, 100);
  color: white;
  cursor: pointer;
  width: auto;
  border: none;
}

.course-book-btn-gray {
  background-color: gray;
}

.course-info-inner-container {
  margin-bottom: 1rem;
}

.course-edit-icons {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}

.course-edit-icons .course-settings-icon {
  font-size: 1.5rem;
  margin-left: 10px;
}

.grayscale {
  filter: grayscale(1) opacity(0.6);
}

.course-booking-detail-btn {
  display: inline-block;
  font-size: 1.2rem;
  padding: 1rem;
  background-color: rgb(100, 146, 207);
  color: white;
  cursor: pointer;
  border: none;
}

.inner-course-container {
  display: flex;
}

@media screen and (max-width: 600px) {
  .course-container {
    flex-direction: column;
  }
  .course-img {
    width: 100% !important;
    max-height: 5rem !important;
    min-height: 0 !important;
    width: auto;
    margin-bottom: 0.5rem;
  }
  .course-info-container {
    padding: 0 !important;
  }

  .course-title-container {
    margin-bottom: 0.2rem !important;
  }

  .course-book-btn {
    display: block !important;
    width: 100%;
    text-align: center;
  }

  .course-booking-detail-btn {
    display: block !important;
    width: 100%;
    text-align: center !important;
  }

  .inner-course-container-editable {
    margin-top: 2.3rem;
  }

  .inner-course-container {
    flex-direction: column;
  }
}
