.subscreen-branch-inner-container {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.subscreen-branch-inner-container::-webkit-scrollbar {
  width: 0.6rem;
}

.subscreen-branch-inner-container::-webkit-scrollbar-track {
  background: #c5c5c5;
}

.subscreen-branch-inner-container::-webkit-scrollbar-thumb {
  background: #8d8d8d;
}

.branches-title-container {
  margin-bottom: 1rem;
}

.branch-subtitle-container {
  margin-bottom: 0.5rem;
}
