.input-container {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
  width: 100%;
  font-family: "Montserrat";
}

.input-container::placeholder {
  color: #b1b1b1;
}