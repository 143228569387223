.footer-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: var(--tertiary-color);
  align-items: center;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 25%);
  font-weight: bold;
}

.footer-links {
  color: var(--tertiary-text-color);
}

@media screen and (max-width: 600px) {
  .footer-container {
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .footer-container a {
    margin-bottom: 0.5rem;
  }
}
